<template>
  <div class="table-editor">
    <script src="https://cdn.jsdelivr.net/npm/hyperformula/dist/hyperformula.full.min.js"></script>

    <table>
      <thead>
        <tr>
          <th></th>
          <th
            v-for="(h, index) in table.headers"
            :key="'header-' + index"
            :style="'background-color:' + h.background + '; width:' + (h.size || 'auto')">
            <v-text-field
              :label="h.label ? '' : $t('bloquesEditTableblockeditor.alphabet', { index: index + 1 })"
              v-model="h.label"
              hide-details="auto">
            </v-text-field>

            <v-menu v-if="ui.mode == 'full'" transition="slide-y-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="btn-actions" v-bind="attrs" v-on="on">
                  <v-icon small>mdi-menu-down</v-icon>
                </div>
              </template>
              <v-list class="pb-0">
                <v-list-item
                  v-for="(action, key) in db.actions.column"
                  :key="index + '-action-'+key"
                  @click="doAction(key, index)">
                  <v-list-item-subtitle>{{ $t('bloquesEditTableblockeditor.' + action.label) }}</v-list-item-subtitle>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <div class="row">
                    <div class="col" cols="6" style="color: rgba(0, 0, 0, 0.6); font-size: 14px">
                      {{ $t('bloquesEditTableblockeditor.size') }}
                    </div>
                    <div
                      class="col text-center"
                      cols="2"
                      style="color: rgba(0, 0, 0, 0.6); font-size: 14px; cursor: pointer"
                      @click="setCellStyle('size', 'auto', index)">
                      {{ $t('bloquesEditTableblockeditor.auto') }}
                    </div>
                    <div
                      class="col text-center"
                      cols="2"
                      style="color: rgba(0, 0, 0, 0.6); font-size: 14px; cursor: pointer"
                      @click="setCellStyle('size', '70px', index)">
                      {{ $t('bloquesEditTableblockeditor.70px') }}
                    </div>
                    <div
                      class="col text-center"
                      cols="2"
                      style="color: rgba(0, 0, 0, 0.6); font-size: 14px; cursor: pointer"
                      @click="setCellStyle('size', '30px', index)">
                      {{ $t('bloquesEditTableblockeditor.30px') }}
                    </div>
                  </div>
                </v-list-item>

                <v-divider></v-divider>
              </v-list>
              <div class="color-picker">
                <div
                  class="color-option"
                  v-for="(color, key) in db.actions.colors"
                  :key="index + '-color-'+key"
                  :style="'background-color: '+color"
                  @click="setCellStyle('background', color, index)">
                  {{ $t('bloquesEditTableblockeditor.color') }}
                </div>
              </div>
            </v-menu>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in table.rows" :key="'row-' + index">
          <th>
            {{ index + 1 }}
            <v-menu transition="slide-y-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="btn-actions" v-bind="attrs" v-on="on">
                  <v-icon small>mdi-menu-down</v-icon>
                </div>
              </template>
              <v-list>
                <v-list-item
                  v-for="(action, key) in db.actions.row"
                  :key="index + '-action-'+key"
                  @click="doAction(key, index)">
                  <v-list-item-subtitle>{{ $t('bloquesEditTableblockeditor.' + action.label) }}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-menu>
          </th>
          <td
            v-for="(field, i) in row"
            :key="'field-'+i+index"
            :class="{ isFormula: isFormula(index, i) }">
            <span v-if="isFormula(index, i)" style="position: absolute; pointer-events: none;">
              {{ calCellValue(index, i) }}
            </span>

            <v-text-field
              label=""
              v-model="row[i]"
              hide-details="auto"
              @blur="parseData"
              v-on:keyup.enter="parseData">
            </v-text-field>

            <v-icon small color="gray" class="formula-icon d-none">mdi-function</v-icon>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr @click="addRowAtIndex('bottom')">
          <th></th>
          <td colspan="99" style="padding: 7px;
            text-align: center;
            cursor: pointer;
            background-color: rgb(236, 245, 240);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 11px;
            color: #717171;
            user-select: none;
            border: 1px dashed #ccc !important;">
            {{ $t('bloquesEditTableblockeditor.newRow') }}
          </td>
        </tr>
        <tr>
          <th></th>
          <td v-for="(f, index) in table.footers" :key="'footer-' + index">
            <template v-if="!table.headers[index].size || table.headers[index].size == 'auto'">
              <span style="text-transform: uppercase; padding-right: 5px;">
                {{ f.formula }}
              </span>
              <strong>{{ calcFormula(f.formula, null, index) }}</strong>
              <v-menu top transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <div class="btn-actions" v-bind="attrs" v-on="on">
                    <v-icon small>mdi-menu-down</v-icon>
                  </div>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(action, key) in db.actions.cell"
                    :key="index + '-action-'+key"
                    @click="doAction(key, index)">
                    <v-list-item-subtitle>{{ $t('bloquesEditTableblockeditor.' + action.label) }}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>


<script>
/**
 * @project: certiapp-nuxt
 * @file:    pages/bloques/edit/tableBlockEditor.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 21st June 2021
 * Last Modified: Mon May 09 2022
 **/

import { uuid } from 'vue-uuid'
import { HyperFormula } from 'hyperformula'

import notifications from '~/util/notificationHelper'

export default {
  props: ['_ui', '_table'],

  data: () => ({
    hf: null,
    table: {
      rows: [
        ['', ''],
        ['', ''],
        ['', ''],
      ],
      headers: [{ label: '' }, { label: '' }],
      footers: [{ formula: 'count' }, { formula: 'sum' }],
    },

    db: {
      alcances: [],
      alphabet: [],

      actions: {
        column: {
          'add-right': {
            label: 'Insertar a la derecha',
          },
          'add-left': {
            label: 'Insertar a la izquierda',
          },
          'remove-col': {
            label: 'Eliminar columna',
          },
        },

        row: {
          'add-top': {
            label: 'Insertar 1 encima',
          },
          'add-bottom': {
            label: 'Insertar 1 debajo',
          },
          'remove-row': {
            label: 'Eliminar fila',
          },
        },

        cell: {
          'formula-sum': {
            label: 'Sumar columna - SUM',
          },
          'formula-count': {
            label: 'Contar columnas - COUNT',
          },
          'formula-average': {
            label: 'Media de columna - AVERAGE',
          },
        },

        colors: [
          '#f0f0f0',
          'antiquewhite',
          'aquamarine',
          'cornflowerblue',
          '#c32c2c',
          '#2cc365',
          '#c3b42c',
        ],
      },

      defaults: {
        row: [],
        header: { label: '' },
        footer: { formula: 'count' },
      },
    },

    ui: {
      mode: 'full',
      loading: false,
      isValid: false,
    },
  }),

  computed: {
    // sheet() {
    //   if (this.hf) return this.hf.getAllSheetsValues().Sheet1
    //   return this.table.rows
    // },
  },

  methods: {
    //+-------------------------------------------------
    // isFormula()
    // Returns true when the expression is a formula
    // -----
    // Created on Thu Jul 01 2021
    //+-------------------------------------------------
    isFormula(row, col) {
      let cell = this.table.rows[row][col]

      if (cell && cell.charAt(0) == '=') return true
      return false
    },

    //+-------------------------------------------------
    // parseData()
    // Gives this.data to hyperformula
    // called to refresh the library
    // -----
    // Created on Wed Jun 30 2021
    //+-------------------------------------------------
    parseData() {
      if (this.hf == null) {
        this.hf = HyperFormula.buildFromArray(this.table.rows, {
          precisionRounding: 4,
          licenseKey: 'agpl-v3',
        })

        // this.hf.addSheet('footer')
        // this.hf.setSheetContent('footer', this.table.footers)
      } else {
        this.hf.setSheetContent('Sheet1', [...this.table.rows])
        console.warn('updating content', this.hf.getAllSheetsValues().Sheet1)
        this.$forceUpdate()

        // this.hf.setSheetContent('footer', this.table.footers)
      }
    },

    //+-------------------------------------------------
    // doAction()
    // Performs an action defined by Key
    // -----
    // Created on Mon Jun 28 2021
    //+-------------------------------------------------
    doAction(action, index, secondary) {
      if (action == 'add-right' || action == 'add-left') {
        let position = action == 'add-right' ? index + 1 : index
        this.addColumnAtIndex(position)
      }

      if (action == 'add-top' || action == 'add-bottom') {
        let position = action == 'add-top' ? index : index + 1
        this.addRowAtIndex(position)
      }

      if (action == 'remove-row') {
        this.removeRow(index)
      }

      if (action == 'remove-col') {
        this.removeCol(index)
      }

      if (action == 'formula-sum') {
        this.setFooterFormula('sum', index)
      }

      if (action == 'formula-count') {
        this.setFooterFormula('count', index)
      }

      if (action == 'formula-average') {
        this.setFooterFormula('average', index)
      }

      console.warn('🛠', 'Action performed: ', action)
    },

    //+-------------------------------------------------
    // addColumnAtIndex()
    // Adds a column to headers and each row
    // -----
    // Created on Mon Jun 28 2021
    //+-------------------------------------------------
    addColumnAtIndex(index) {
      let header = { ...this.db.defaults.header }
      let footer = { ...this.db.defaults.footer }

      this.table.headers.splice(index, 0, header)
      this.table.footers.splice(index, 0, footer)

      this.table.rows.forEach((row) => {
        row.splice(index, 0, '')
      })
    },

    //+-------------------------------------------------
    // addRowAtIndex()
    // Adds a new row
    // -----
    // Created on Tue Jun 29 2021
    //+-------------------------------------------------
    addRowAtIndex(index) {
      index = index == 'bottom' ? this.table.rows.length : index

      let newRow = []
      this.table.headers.forEach((item) => {
        newRow.push('')
      })

      this.table.rows.splice(index, 0, newRow)
    },

    removeRow(index, type) {
      this.table.rows.splice(index, 1)
    },

    removeCol(index, type) {
      this.table.rows.forEach((row) => {
        row.splice(index, 1)
      })
      this.table.footers.splice(index, 1)
      this.table.headers.splice(index, 1)
    },

    //+-------------------------------------------------
    // calCellValue()
    // Calculates the cell value with the hyperformula library
    // -----
    // Created on Thu Jul 01 2021
    //+-------------------------------------------------
    calCellValue(row, col) {
      let value = this.hf.getCellValue({ col: col, row: row, sheet: 0 })
      return value || '...'
    },

    //+-------------------------------------------------
    // calcFormula()
    // Calculates formulas
    // -----
    // Created on Wed Jun 30 2021
    //+-------------------------------------------------
    calcFormula(formula, row, col) {
      let value = 0
      let sheet = this.hf ? [...this.hf.getAllSheetsValues().Sheet1] : this.table.rows

      // SUM
      // Created on Wed Jun 30 2021
      //+-------------------------------------------------
      if (formula == 'sum') {
        sheet.forEach((row) => {
          let item = row[col]
          if (item) {
            item = item.replace ? item.replace(',', '.') : item
            let parsed = parseFloat(item)
            value += parsed
          }
        })
        value = value.toFixed(2)
      }

      // AVERAGE
      // Created on Wed Jun 30 2021
      //+-------------------------------------------------
      if (formula == 'average') {
        let sum = this.calcFormula('sum', row, col)
        let count = this.calcFormula('count', row, col)
        value = sum / count

        value = value.toFixed(2)
      }

      // COUNT
      // Created on Thu Jul 01 2021
      //+-------------------------------------------------
      if (formula == 'count') {
        value = this.table.rows.length
      }

      return value
    },

    //+-------------------------------------------------
    // setCellStyle()
    // Sets additional values to cell
    // -----
    // Created on Wed Jun 30 2021
    //+-------------------------------------------------
    setCellStyle(style, value, index) {
      this.table.headers[index][style] = value
      this.$forceUpdate()
    },

    //+-------------------------------------------------
    // setFooterFormula()
    // Defines a formula in a cell
    // -----
    // Created on Wed Jun 30 2021
    //+-------------------------------------------------
    setFooterFormula(formula, column) {
      this.table.footers[column] = {
        formula: formula,
      }

      this.$forceUpdate()
    },

    //+-------------------------------------------------
    // getDataFromProps()
    // Assigns this.table values from _data
    // -----
    // Created on Tue Jul 06 2021
    //+-------------------------------------------------
    getDataFromProps() {
      if (this._table && this._table.rows) {
        this.table = { ...this._table }
      }
      console.warn(this._table.headers, this.table.headers)

      this.ui.mode = this._ui?.mode || 'limited'
    },

    //+-------------------------------------------------
    // normalizeColsAndRows()
    // Ensures that there is enough elements in each row
    // for every header
    // -----
    // Created on Wed Jun 30 2021
    //+-------------------------------------------------
    normalizeColsAndRows() {
      let headers = this.table.headers.length
      this.table.rows.forEach((row) => {
        if (row.length < headers) {
          for (let i = row.length; i < headers; i++) {
            row.push('')
          }
        }
      })
    },

    createKeyedAlphabet() {
      var a = 97
      var charArray = {}
      for (var i = 0; i < 26; i++) charArray[i + 1] = String.fromCharCode(a + i).toUpperCase()

      this.db.alphabet = charArray
    },

    //+-------------------------------------------------
    // Funciones de flujo
    // Submit, validate, Upload...
    //+-------------------------------------------------

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    //+-------------------------------------------------
    // Async API requests
    //+-------------------------------------------------

    async init() {
      this.getDataFromProps()
      this.createKeyedAlphabet()
      this.normalizeColsAndRows()
      this.parseData()
    },
  },

  mounted() {
    this.init()
  },
}
</script>

<style>
.table-editor {
  background: whitesmoke;
  box-shadow: 0px 0px 2px #dedede;
}

.table-editor table {
  width: 100%;
  border-collapse: collapse;
}

.table-editor th,
.table-editor td {
  position: relative;
  border-top-width: 0;
  border-left-width: 0;
  height: 22px;
  empty-cells: show;
  line-height: 21px;
  padding: 0 4px;
  background-color: #fff;
  vertical-align: top;
  overflow: hidden;
  outline-width: 0;
  white-space: pre-wrap;

  border: 1px solid #ccc;
}

.table-editor th {
  background-color: #f0f0f0;
  color: #222;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  height: 26px;
  line-height: 25px;
}

.table-editor tbody th {
  width: 60px;
}

.table-editor td {
  color: #222;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  white-space: nowrap;
}

.table-editor tfoot td {
  background-color: #f0f0f0;
  text-align: right;
  padding-right: 30px;
}

td.isFormula {
  background-color: aliceblue;
}

td.isFormula .v-input {
  width: 100%;
  display: inline-block;
  opacity: 0;
}

td.isFormula:hover .v-input {
  opacity: 1;
}

td.isFormula:hover span {
  opacity: 0;
}

td.isFormula .formula-icon {
  font-size: 16px;
  display: inline-block !important;
  transform: translateX(-25px);
}

.table-editor .btn-actions {
  opacity: 0;
  outline: 1px dashed #d8d8d8;
  outline-offset: -1px;
  position: absolute;
  right: 4px;
  top: 5px;
  line-height: 14px;
  padding: 0px;
  transition: opacity 0.3s;
}

.table-editor th:hover .btn-actions,
.table-editor td:hover .btn-actions {
  opacity: 1;
}

.v-menu__content .color-picker {
  max-width: 240px;
  background: white;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.v-menu__content .color-option {
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  outline: 2px solid #ccc;
  outline-offset: -2px;
}

.table-editor .v-input,
.table-editor .v-input .v-label {
  padding: 0;
  margin: 0;
  color: #222;
  font-size: inherit;
}

.table-editor .v-input .v-label {
  transform: translate(0px, -2px);
}

.table-editor input {
  padding: 0;
  margin: 0;
  line-height: unset;
}
</style>
