<template>
  <div class="input-wrapper" v-if="tipo === 'text'">
    <label for="field_value">{{ nombre }}</label>
    <div style="height: 100%; align-content: flex-end">
      <v-textarea
        dense
        :key="componentKey"
        v-model="field_value"
        :rules="required ? RULES.REQUIRED : []"
        hide-details="auto"
        :rows="5"
        :outlined="outlined"
        :no-resize="true"
        :disabled="disabled"></v-textarea>
    </div>
  </div>
  <div class="input-wrapper" v-else-if="tipo === 'character'" :disabled="disabled">
    <label for="field_value">{{ nombre }}</label>
    <div style="height: 100%; align-content: flex-end">
      <v-text-field
        dense
        :key="componentKey"
        :rules="required ? RULES.REQUIRED : []"
        v-model="field_value"
        :outlined="outlined"
        :disabled="disabled"
        hide-details="auto"></v-text-field>
    </div>
  </div>
  <div class="input-wrapper" v-else-if="tipo === 'int'">
    <label for="field_value">{{ nombre }}</label>
    <div style="height: 100%; align-content: flex-end">
      <v-text-field
        type="number"
        dense
        :key="componentKey"
        :rules="RULES.REQUIRED.concat(RULES.NUMBER)"
        :outlined="outlined"
        v-model="field_value"
        hide-details="auto"></v-text-field>
    </div>
  </div>
  <div class="input-wrapper" v-else-if="tipo === 'decimal'">
    <label for="field_value">{{ nombre }}</label>
    <div style="height: 100%; align-content: flex-end">
      <v-text-field
        type="number"
        step="0.01"
        dense
        :key="componentKey"
        :rules="RULES.REQUIRED"
        v-model="field_value"
        :outlined="outlined"
        hide-details="auto"></v-text-field>
    </div>
  </div>
  <div class="input-wrapper" v-else-if="tipo === 'tinyint'">
    <label for="field_value">{{ nombre }}</label>
    <div style="height: 100%; align-content: flex-end">
      <v-checkbox dense v-model="field_value" hide-details="auto"></v-checkbox>
    </div>
  </div>
  <div class="input-wrapper" v-else-if="tipo === 'date'">
    <label for="field_value">{{ nombre }}</label>
    <div style="height: 100%; align-content: flex-end">
      <v-menu
        ref="campo_fecha_picker"
        v-model="ui.fecha_picker"
        :return-value.sync="field_value"
        :close-on-content-click="false"
        :key="componentKey"
        transition="scale-transition"
        offset-y
        min-width="auto">
      </v-menu>
    </div>
  </div>
  <div class="input-wrapper" v-else-if="tipo === 'time'">
    <label for="field_value">{{ nombre }}</label>
    <div style="height: 100%; align-content: flex-end">
      <v-menu
        ref="time_picker"
        v-model="ui.time_picker"
        :return-value.sync="field_value"
        :close-on-content-click="false"
        :nudge-right="40"
        :key="componentKey"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px">
      </v-menu>
    </div>
  </div>
  <div class="input-wrapper" v-else-if="tipo === 'titulo'">
    <label>{{ nombre }}</label>
    <v-card-subtitle class="px-0 pb-2"></v-card-subtitle>
    <v-divider class="mt-0 mb-4"></v-divider>
  </div>
  <div class="input-wrapper" v-else-if="tipo === 'estatico'">
    <label for="field_value">{{ nombre }}</label>
    <div style="height: 100%; align-content: flex-end">
      <SelectEstaticos
        dense
        v-model="field_value"
        :key="componentKey"
        :codigo="estatico ? estatico.codigo : ''"
        :multiple="multiple"
        :required="required"
        :subalcance="subalcance" />
    </div>
  </div>
  <div class="input-wrapper" v-else-if="tipo === 'emplazamiento'">
    <label for="field_value">{{ nombre }}</label>
    <div style="height: 100%; align-content: flex-end">
      <SelectEmplazamientos
        dense
        v-model="field_value"
        :tipo="'tipos-de-direccion'"
        :key="componentKey"
        :user="cliente"
        :multiple="multiple"
        :subalacance_tipo="subalacance_tipo"
        required
        :label="false" />
    </div>
  </div>
  <div class="input-wrapper" v-else-if="tipo === 'yesno'">
    <label for="field_value">{{ nombre }}</label>
    <div style="height: 100%; align-content: flex-end">
      <v-select
        dense
        v-model="field_value"
        :items="itemsSelectYesNo"
        item-text="text"
        item-value="value"
        :key="componentKey"
        :outlined="outlined"
        placeholder="Seleccione"
        :rules="required ? RULES.BOOLEAN : []"
        :required="required"></v-select>
    </div>
  </div>
</template>

<script>
import RULES from '~/models/form.rules'
import SelectEstaticos from '~/components/forms/SelectEstaticos.vue'
import SelectEmplazamientos from '~/components/forms/SelectEmplazamientosObject.vue'

export default {
  name: 'CampoFormulario',

  props: {
    tipo: { type: String, default: '' },
    nombre: { type: String, default: '' },
    valor: { type: null, default: '' },
    estatico: { type: Object, default: null },
    cliente: { type: Object, default: null },
    multiple: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    subalacance_tipo: { type: String, default: '' },
    subalcance: { type: Object, default: null },
    isNew: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
  },

  components: { SelectEstaticos, SelectEmplazamientos },

  data() {
    return {
      field_value: '',
      itemsSelectYesNo: [
        { text: 'SI', value: 1 },
        { text: 'NO', value: 0 },
      ],
      RULES,
      ui: {
        loading: false,
        isValid: false,
        time_picker: false,
        fecha_picker: false,
      },
      componentKey: 0,
    }
  },

  watch: {
    valor(val) {
      this.field_value = val
      if (typeof this.valor === 'object' && this.valor.linkEstaticoValue) {
        this.field_value = this.valor.value
        console.warn('valor_campo_linkEstaticoValue', val)
      }
      //check if this.valor is an array
      if (Array.isArray(this.valor) && this.multiple) {
        this.field_value = this.valor
      } else {
        this.field_value = [this.valor]
        console.warn('valor_campo_isArray', this.field_value)
      }
      if (this.tipo === 'yesno') {
        this.field_value = Array.isArray(this.valor) ? this.valor[0] : this.valor
        console.warn('valor_campo_yesno', this.field_value)
      } else if (this.tipo === 'tinyint') {
        this.field_value = Array.isArray(this.valor) ? this.valor[0] : this.valor
        console.warn('valor_campo_tinyint', this.field_value)
      }
      /* else if (this.tipo === 'emplazamiento') {
        console.warn('valor_campo_form4')
        this.field_value = Array.isArray(this.valor) ? this.valor[0].nombre : this.valor.nombre
        console.warn('valor_campo_form4', this.field_value)
      } */
      this.$forceUpdate()
      //this.field_value = this.valor;
    },
    field_value: function (value) {
      if (this.tipo === 'emplazamiento') {
        if (this.multiple) {
          console.log('field_value_emplazamiento multiple', value)
          /* if (Array.isArray(value)) {
            value = value.map((e) => {
              if (typeof e === 'object') {
                return e.uuid
              } else {
                return e
              }
            })
          } */
        } else {
          console.log('field_value_emplazamiento', value)
          if (Array.isArray(value)) value = value[0]
          if (typeof value === 'object' && this.subalacance_tipo == 'grid') {
            console.log('field_value_emplazamiento2', value)
            value = value[0]
          }
          if (typeof value === 'object' && this.subalacance_tipo !== 'grid') {
            value = value.uuid
            console.log('field_value_emplazamiento3', value)
          }
        }
      }
      if (!this.multiple) {
        if (this.tipo === 'tinyint') {
          console.warn('field_value_tinyint', value)
          value = Array.isArray(value) ? value[0] : value
        }

        if (this.tipo === 'character') {
          console.warn('field_value_character', value)
          value = Array.isArray(value) ? value[0] : value
        }
        if (this.tipo === 'decimal') {
          console.warn('field_value_decimal', value)
          value = Array.isArray(value) ? value[0] : value
        }
        if (this.tipo === 'text') {
          console.warn('field_value_text', value)
          value = Array.isArray(value) ? value[0] : value
        }
        if (this.tipo === 'estatico') {
          console.warn('field_value_estatico', value)
          value = Array.isArray(value) ? value[0] : value
          if (Array.isArray(value)) {
            value = value[0]
          }
        }
      } else {
        if (this.tipo === 'estatico') {
          //we need the array inside the array item[0][theitem]
          console.warn('field_value_estatico_multiple', value)
            while (Array.isArray(value)) {
            value = value[0];
            }
        }
      }
      this.emitChange(value)
      if (this.tipo !== 'character') {
        this.componentKey++
      }
    },
  },

  computed: {
    computedValue() {
      return this.valor
    },
    isCerticalidad() {
      return this.$auth.user.empresa.codigo == 'certicalidad'
    },
  },

  methods: {
    async init() {
      if (this.tipo === 'tinyint' && this.valor === '') {
        this.field_value = false
      }
    },
    
    emitChange(value) {
      // console.warn('emitChange', value)

      if (value !== null) {
        this.$emit('change', value)
        this.$emit('input', value)
      }
    },
  },

  mounted() {
    if (typeof this.valor === 'object' && this.valor.linkEstaticoValue) {
      this.field_value = this.valor.value
    } else {
      this.field_value = this.valor
    }

    this.init()
  },

  beforeDestroy() {},
}
</script>

<style scoped>
.input-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
