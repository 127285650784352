<template>
  <div>
    <v-card class="mt-5 pb-5" flat>
      <v-card-title>
        {{ $t('certificadoSites.certificaciones') }}
        <v-spacer></v-spacer>
        <template>
          <v-btn
            :key="ui.refresh"
            v-if="isCCLVegetal"
            small
            text
            depressed
            color="primary"
            :disabled="disabled"
            @click="doImportGrid">
            {{ $t('certificadoSites.importarExcel') }}
          </v-btn>

          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                text
                depressed
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="disabled">
                {{ $t('certificadoSites.anadirAmpliacion') }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="ui.selectTipo = true">
                <v-list-item-title>{{ $t('certificadoSites.nueva') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="ui.selectFromIteracion = true">
                <v-list-item-title>{{
                  $t('certificadoSites.anadirDesdeIteracion')
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-card-title>
      <v-card-text>
        <v-row v-for="emplazamiento in emplazamientos" :key="emplazamiento.tipo">
          <v-col v-if="emplazamiento.productos.length > 0" cols="12">
            <h4 class="mt-1 mb-2">{{ emplazamiento.tipo }}</h4>
          </v-col>
          <template v-if="subalcance_tipo === 'grid'">
            <v-data-table
              v-if="emplazamiento.productos.length > 0"
              ref="datatable"
              v-model="table.selected"
              :hide-default-footer="true"
              sort-by="updatedAt"
              :loading-text="$t('certificadoSites.cargando')"
              :headers="getHeaders(emplazamiento.uuid)"
              :items="listadoProductos(emplazamiento.tipo)"
              :items-per-page="20"
              item-key="uuid"
              class="elevation-1"
              :class="{ disabled: ui.loading }"
              style="border: 3px solid #e0e0e0">
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="disabled"
                      small
                      dense
                      text
                      color="darken-3"
                      @click.stop="rowAction_(item)"
                      v-bind="attrs"
                      v-on="on"
                      style="min-width: 30px">
                      <v-icon style="font-size: 20px">mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('certificadoSites.modificar') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="disabled"
                      small
                      dense
                      text
                      color="darken-3"
                      @click.stop="
                        ui.confirm = true
                        ui.confirmRemove = item
                      "
                      v-bind="attrs"
                      v-on="on"
                      style="min-width: 30px">
                      <v-icon style="font-size: 20px">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('certificadoSites.eliminar') }}</span>
                </v-tooltip>
              </template>

              <template
                v-for="header in getHeaders2(emplazamiento.uuid)"
                v-slot:[`item.${header.value}`]="{ item }">
                <template
                  v-if="
                    getHeaderValue(item, header) === true || getHeaderValue(item, header) === false
                  ">
                  <v-icon v-if="getHeaderValue(item, header) === true" color="green"
                    >mdi-check</v-icon
                  >
                  <v-icon v-else color="red">mdi-close</v-icon>
                </template>
                <template v-else>
                  {{ getHeaderValue(item, header) }}
                </template>
              </template>
            </v-data-table>
          </template>
          <template v-else>
            <v-data-table
              v-if="emplazamiento.productos.length > 0"
              ref="datatable"
              v-model="table.selected"
              :hide-default-footer="true"
              sort-by="updatedAt"
              :loading-text="$t('certificadoSites.cargando')"
              :headers="getHeaders(emplazamiento.uuid)"
              :items="listadoProductos(emplazamiento.tipo)"
              :items-per-page="20"
              item-key="uuid"
              class="elevation-1"
              :class="{ disabled: ui.loading }"
              style="border: 3px solid #e0e0e0">
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="disabled"
                      small
                      dense
                      text
                      color="darken-3"
                      @click.stop="rowAction_(item)"
                      v-bind="attrs"
                      v-on="on"
                      style="min-width: 30px">
                      <v-icon style="font-size: 20px">mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('certificadoSites.modificar') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="disabled"
                      small
                      dense
                      text
                      color="darken-3"
                      @click.stop="
                        ui.confirm = true
                        ui.confirmRemove = item
                      "
                      v-bind="attrs"
                      v-on="on"
                      style="min-width: 30px">
                      <v-icon style="font-size: 20px">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('certificadoSites.eliminar') }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.instalacion="{ item }">
                {{ item.emplazamiento ? item.emplazamiento.nombre : '' }}
              </template>

              <template
                v-for="header in getHeaders2(emplazamiento.uuid)"
                v-slot:[`item.${header.value}`]="{ item }">
                <template
                  v-if="
                    getHeaderValue(item, header) === true || getHeaderValue(item, header) === false
                  ">
                  <v-icon v-if="getHeaderValue(item, header) === true" color="green"
                    >mdi-check</v-icon
                  >
                  <v-icon v-else color="red">mdi-close</v-icon>
                </template>
                <template v-else>
                  {{ getHeaderValue(item, header) }}
                </template>
              </template>
            </v-data-table>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
    <form-grid-dinamico
      :cliente="cliente"
      ref="form_grid_dinamico"
      @stored="addGridRow($event)"
      :tipo="subalcance_tipo" />
    <confirmation
      v-model="ui.confirmRemove"
      :text="$t('certificadoSites.eliminarAmpliacion')"
      :secondaryText="$t('certificadoSites.eliminarAmpliacionCompleta')"
      @confirm="remove"
      :textButton="$t('certificadoSites.eliminarAuditoria')"
      :secondaryTextButton="$t('certificadoSites.eliminarCompletamente')"
      secondary
      @confirm2="removeCompletely"
      conditions />
    <v-dialog v-model="ui.selectTipo" max-width="500">
      <v-card>
        <v-card-title class="my-1">{{ $t('certificadoSites.anadirAmpliacion') }}</v-card-title>
        <v-card-subtitle>{{ $t('certificadoSites.seleccionaProducto') }}</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="selectTipo"
                dense
                :items="Object.keys(productos).map((key) => $t('certificadoSites.producto') + key)"
                :label="$t('certificadoSites.tipoAmpliacion')"
                outlined></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="
              ui.selectTipo = false
              selectTipo = false
            "
            >{{ $t('certificadoSites.cancelar') }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              ui.selectTipo = false
              addAmpliacion()
            "
            >{{ $t('certificadoSites.aceptar') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ui.selectFromIteracion" max-width="1000">
      <v-card>
        <v-card-title class="my-1">{{ $t('certificadoSites.anadirAmpliacion') }}</v-card-title>
        <v-card-subtitle>{{ $t('certificadoSites.seleccionaProducto') }}</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <SelectEmplazamientoProductosFromIteracion
                :emplazamientos="iteracion?.emplazamientos"
                :productos="this.productosRestantes"
                :subalcance="auditoria?.subalcance"
                @updateSelectedProductos="updateSelectedProductos($event)" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="
              ui.selectFromIteracion = false
              selectTipo = false
            "
            >{{ $t('certificadoSites.cancelar') }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="productosFromIteracion.length == 0"
            color="primary"
            @click="
              ui.selectFromIteracion = false
              addAmpliaciones()
            "
            >{{ $t('certificadoSites.anadir') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    CertificadoSites.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 13th August 2021
 * Last Modified: Fri Jul 07 2023
 **/
import { uuid } from 'vue-uuid'

import Confirmation from '~/components/Confirmation'
import format from '~/util/formatHelper'
import ampliacionOtros from './ampliacionOtros'
import FormGridDinamico from '~/components/solicitudes/FormGridDinamico.vue'
import SelectEmplazamientoProductosFromIteracion from './SelectEmplazamientoProductosFromIteracion.vue'

export default {
  name: 'CertificadoSites',
  props: {
    disabled: { type: Boolean, default: false },
    sites: { type: Array, default: () => [] },
    cliente: { type: Object, default: () => {} },
    alcance: { type: Object, default: () => {} },
    auditoria: { type: Object, default: () => {} },
    planificaciones: { type: Array, default: () => [] },
    iteracion: { type: Object, default: () => {} },
    subalcance_tipo: { type: String, default: '' },
  },

  components: {
    // Ampliacion,
    Confirmation,
    // ampliacionCcl,
    // ampliacionCclVegetal,
    // ampliacionCclEcologicos,
    ampliacionOtros,
    // ampliacionCerticar,
    // ampliacionAraporcei,
    // ampliacionCerticalidad,
    // ampliacionCerticalidadSAE,
    // ampliacionCerticalidadETG,
    SelectEmplazamientoProductosFromIteracion,
    // ProduccionVegetalList,
    // ProductosEcologicosList,
    FormGridDinamico,
  },

  data: function () {
    return {
      certificadoSites: [],
      itemSelected: {},
      productos: [],
      // items que no son de alcance propio
      // Seteado en la computed items
      productosFromIteracion: [],
      items_: [],
      offline: [],
      selectTipo: false,
      table: {
        selected: [],
        showSelect: false,
        headers: [
          { text: '', value: 'actions', sortable: false, width: 130 },
          {
            text: 'Instalación',
            value: 'instalacion',
            cellClass: 'subData',
            sortable: false,
            width: 130,
          },
          {
            text: 'RGSEAA',
            value: 'rgseaa',
            cellClass: 'subData',
            sortable: false,
            width: 130,
          },
          {
            text: 'Tipo',
            value: 'data.tipo',
            cellClass: 'subData capitalize',
            sortable: false,
            width: 130,
          },
        ],

        dinamicHeaders: [],
      },

      db: {
        propias: ['actividad', 'actividad propia', 'parcela', 'parcelas', 'producto'],
      },

      ui: {
        f: format,
        refresh: 0,

        dialog: false,
        dialog_: false,
        confirm: false,
        confirmRemove: false,
        confirmSecondary: false,
        isNew: false,
        selectTipo: false,
        selectFromIteracion: false,
      },
    }
  },

  watch: {
    sites() {
      this.init()
    },
  },

  computed: {
    isCoplaca() {
      return this.$auth.user.empresa?.codigo == 'coplaca'
    },
    emplazamientos() {
      let acc = []
      /*
      this.sites.forEach((emplazamiento) => {
        //verificar si el emplazamiento.tipo ya existe en el array acc
        if (this.subalcance_tipo !== 'grid') {
          if (!acc.find((item) => item.tipo === emplazamiento.tipo)) {
            acc.push(emplazamiento)
          }
        } else {
          if (!acc.find((item) => item.tipo === emplazamiento.tipo)) {
            acc.push(emplazamiento)
          }
        }
      })
      console.log('emplazamientos', acc)
      return acc
      */

      this.iteracion?.emplazamientos?.forEach((emplazamiento) => {
        //verificar si el emplazamiento.tipo ya existe en el array acc
        if (!acc.find((item) => item.tipo === emplazamiento.tipo)) {
          emplazamiento.productos = this.listadoProductos(emplazamiento.tipo)
          acc.push(emplazamiento)
        }
      })

      return acc
    },

    listadoEmplazamientos() {
      let acc = []
      this.emplazamientos.forEach((emplazamiento) => {
        if (!acc.find((item) => item.tipo === emplazamiento.tipo)) {
          acc.push({ tipo: emplazamiento.tipo, uuid: emplazamiento.uuid })
        }
      })
      return acc
    },

    productosRestantes() {
      // Obtener todos los productos de los emplazamientos
      const productosEmplazamientos = this.emplazamientos.flatMap(
        (emplazamiento) => emplazamiento.productos
      )

      // Filtrar productos que están en iteracion.productos y no en productosEmplazamientos
      return this.iteracion?.productos?.filter((productoIteracion) => {
        return !productosEmplazamientos.some(
          (productoEmplazamiento) => productoEmplazamiento.uuid === productoIteracion.uuid
        )
      })
    },

    //+-------------------------------------------------
    // isAmpliacionEnabled()
    // Property used to enable the buttons based on the cliente and alcance
    // Done manually as every client has different needs with own components
    // Returns: Boolean
    // -----
    // Created on Wed Sep 08 2021
    // Created on Tue Sep 20 2022
    //+-------------------------------------------------
    isAmpliacionEnabled() {
      if (this.cliente?.empresa?.codigo == 'ccl') {
        if (this.alcance?.codigo == 'marcas') return true
        if (this.alcance?.codigo == 'produccion_ecologica') return true
      }

      if (this.cliente?.empresa?.codigo == 'coplaca') {
        if (this.alcance?.codigo == 'unegap') return true
      }

      if (this.cliente?.empresa?.codigo == 'araporcei') {
        if (this.alcance?.codigo == 'productos_ibericos') return true
      }

      if (this.cliente?.empresa?.codigo == 'certicar') {
        if (this.alcance?.codigo == 'certicar-iberico') return true
      }

      if (this.cliente?.empresa?.codigo == 'certicalidad') {
        if (this.alcance?.codigo == 'certicalidad_sae') return true
        if (this.alcance?.codigo == 'certicalidad-etg') return true
        if (this.alcance?.codigo == 'certicalidad_productos_ibericos') return true
      }

      return false
    },

    //+-------------------------------------------------
    // isOtrosEnabled()
    // Returns true for a manual list of empresas
    // We set true here if the ampliacionOtros modal has been
    // updated with the list of actividades
    // -----
    // Created on Tue Feb 22 2022
    //+-------------------------------------------------
    isOtrosEnabled() {
      if (this.cliente?.empresa?.codigo == 'araporcei') {
        if (this.alcance?.codigo == 'productos_ibericos') return true
      }

      if (this.cliente?.empresa?.codigo == 'certicalidad') {
        if (this.alcance?.codigo == 'certicalidad_productos_ibericos') return true
        if (this.alcance?.codigo == 'certicalidad-etg') return true
      }

      return false
    },

    //+-------------------------------------------------
    // isAmpliacionAvailable()
    // When isAmpliacionEnabled is true, check if the component is available
    // Used to display the edit button
    // NOTE: CCL has multiple components to edit in the same alcance.
    // -----
    // Created on Tue Jan 18 2022
    // Updated on Wed Jun 01 2022
    //+-------------------------------------------------
    isAmpliacionAvailable() {
      if (!this.isAmpliacionEnabled) return false
      return this.$options.components[this.ampliacion] ? true : false
    },

    ampliacion() {
      let empresa = this.cliente?.empresa?.codigo || 'xx'
      empresa = empresa.charAt(0).toUpperCase() + empresa.slice(1)

      if (empresa == 'Ccl' && this.certificadoSites.length) {
        if (this.certificadoSites[0] && this.certificadoSites[0].subtipo) {
          if (this.certificadoSites[0].subtipo.indexOf('PV') > -1) empresa = 'CclVegetal'
          else empresa = 'CclEcologicos'
        }
      }

      if (this.isCertSAE) empresa = 'CerticalidadSAE'
      if (this.isCertETG) empresa = 'CerticalidadETG'

      this.ui.refresh++

      return 'ampliacion' + empresa
    },

    parentSites() {
      return this.sites.filter((e) => !e.parent_uuid)
    },

    childrenSites() {
      let parents = []
      const plan_sites = this.planificaciones.map((e) => e.site)
      const hasChildSites = this.checkIfHasChildSites()

      plan_sites.map((plansite) => {
        this.sites.map((site) => {
          if (site.parent_uuid === undefined) {
            if (hasChildSites == false && plansite.uuid == site.uuid) {
              parents.push(site)
            }
          } else {
            if (site.parent_uuid == plansite.uuid) {
              parents.push(site)
            }
          }
        })
      })
      return parents
    },

    items() {
      let items = []
      let items_ = []

      if (this.certificadoSites.length == 0) return []

      this.certificadoSites.forEach((item) => {
        if (item.tipo.indexOf('instalacion') > -1) return

        let object = {
          data: item,
          uuid: item.uuid,
          name: item.titulo, //.toLowerCase(),
          date: item.created_at || '--',
          instalacion: item.instalacion?.razon_social || '--',
          rgseaa: item.instalacion?.rgseaa || 'Sin rgseaa',
        }

        const propias = this.db.propias
        if (propias.indexOf(item.tipo) > -1) items.push(object)
        else items_.push(object)
      })

      this.offline.forEach((item) => {
        let object = {
          data: item,
          uuid: item.uuid,
          name: item.nombre, //.toLowerCase(),
          instalacion: item.instalacion?.razon_social || '--',
          isOffline: true,
          rgseaa: item.instalacion?.rgseaa || 'Sin rgseaa',
        }

        items.push(object)
      })

      this.items_ = items_
      return items
    },

    isProductosIbericos() {
      return (
        this.alcance.codigo == 'productos_ibericos' ||
        this.alcance.codigo == 'certicalidad_productos_ibericos'
      )
    },

    isCertSAE() {
      return this.alcance.codigo == 'certicalidad_sae'
    },

    isCertETG() {
      return this.alcance.codigo == 'certicalidad-etg'
    },

    isCCLVegetal() {
      return this.$auth.user.empresa.nombre == 'Ccl' && this.ampliacion == 'ampliacionCclVegetal'
    },

    //+-------------------------------------------------
    // isOperadorResposable()
    // Only used in ARAPORCEI
    // always return true because can't know the alcance_proceso
    // -----
    // Created on Wed Dec 01 2021
    //+-------------------------------------------------
    isOperadorResposable() {
      return true
      return this.item.params.alcance_proceso.tipo_operador.includes('responsable')
    },
  },

  methods: {
    getHeaderValue(item, header) {
      const param = item.params.find((param) => param.nombre === header.value)
      if (param) {
        const valor = param.valor
        if (Array.isArray(valor)) {
          if (valor.length > 0) {
            if (!typeof valor[0] === 'number' && typeof valor[0] === 'boolean') {
              return valor[0]
            } else return valor[0] !== null ? valor.join(', ') : param.displayValue
          }
        } else if (typeof valor === 'object') {
          return valor.nombre
        }
      }
      return ''
    },
    listadoProductos(tipo) {
      if (tipo === undefined) {
        console.error('El tipo no puede ser undefined')
        return []
      }

      let productos = []
      this.sites.forEach((site) => {
        site.productos?.forEach((producto) => {
          if (producto.emplazamiento?.tipo === tipo) {
            productos.push(producto)
          }
        })
      })

      return productos
    },

    addAmpliacion() {
      this.$refs.form_grid_dinamico.reset()
      // elimina Producto de la cadena
      let key = this.selectTipo.replace(
        new RegExp(`^${this.$t('certificadoSites.producto')}\\s*`),
        ''
      )
      console.log(key)
      console.log(this.productos)
      let params = this.productos[key]
      params = Object.values(params).map((item, index) => {
        return {
          nombre: item.nombre,
          valor: item.tipo === 'tinyint' ? false : '',
          tipo: item.tipo,
          estatico: item.estatico,
          selector: item.linkEstatico,
          multiple: item.multiple,
          orden: item.orden ? item.orden : index,
          imprimible: true,
          subalcance: this.auditoria.subalcance.uuid,
        }
      })
      this.items_ = params
      //debugger
      if (this.subalcance_tipo !== 'grid') {
        this.items_.push({
          tipo: 'emplazamiento',
          nombre: 'Dirección',
          imprimible: true,
          defineAlcance: true,
          gridConfig: [],
          orden: 1,
          obligatorio: true,
        })
      }
      let group = 'productos'
      let uuid = this.sites[0].uuid
      let gridConfig = params
      this.$refs.form_grid_dinamico.new(group, uuid, gridConfig, key)
      this.selectTipo = false
    },
    async addGridRow(event) {
      if (event.action === 'create') {
        let ampliacion = {}
        if (this.subalcance_tipo !== 'grid') {
          ampliacion = {
            uuid: uuid.v4(),
            tipo: event.tipo,
            direccion: event.item.Dirección,
            configProducto: [],
          }
        } else {
          ampliacion = {
            uuid: uuid.v4(),
            tipo: event.tipo,
            direccion: '',
            configProducto: [],
          }
        }
        let params = Object.keys(event.item).map((key) => {
          return {
            nombre: key,
            valor: event.item[key],
          }
        })
        params.forEach((item) => {
          let param = this.items_.find((param) => param.nombre === item.nombre)
          if (param) {
            param.value = typeof item.valor === 'object' ? item.valor.nombre : item.valor
            if (typeof item.valor === 'object') {
              param.emplazamiento_uuid = item.valor.uuid
              param.emplazamientoUuid = item.valor.uuid
              ampliacion.direccion = item.valor.uuid
            }
            //check if item.valor is a uuid
            if (item.valor.length === 36 && this.subalcance_tipo === 'grid') {
              param.emplazamiento_uuid = item.valor
              param.emplazamientoUuid = item.valor
              let nombre = item.nombre
              ampliacion.direccion = event.item[nombre]
              item.valor = this.sites.find((site) => site.direccion.uuid === item.valor).nombre
            }
            delete param.valor
            ampliacion.configProducto.push(param)
          }
        })

        // Ordenar los elementos de la nueva ampliación según el orden de los elementos en this.items_.
        if (this.items_.length > 0) {
          let ordenDeseado = {}
          this.items_.forEach((item, index) => {
            ordenDeseado[item.nombre] = index
          })

          ampliacion.configProducto.sort((a, b) => {
            let indexA = ordenDeseado[a.nombre] !== undefined ? ordenDeseado[a.nombre] : Infinity
            let indexB = ordenDeseado[b.nombre] !== undefined ? ordenDeseado[b.nombre] : Infinity
            return indexA - indexB
          })
        }

        ampliacion.configProducto.forEach((item) => {
          if (item.tipo === 'tinyint' && item.value === '') {
            item.value = false
          }
        })

        // Imprimir el resultado.

        const xhr = await this.$store.dispatch('auditorias/addAmpliacion', {
          auditoria: this.auditoria.uuid,
          ampliacion: ampliacion,
        })

        if (xhr) {
          this.$store.commit('notification/show', {
            text: this.$t('certificadoSites.ampliacionCreada'),
            color: 'success',
          })

          this.$emit('init')
        } else {
          this.$store.commit('notification/show', {
            text: this.$t('certificadoSites.errorAmpliacion'),
            color: 'error',
            timeout: 3000,
          })
        }
      }
      if (event.action === 'update') {
        let item = this.auditoria.productos.find((site) => site.uuid === event.uuid_config)
        Object.keys(event.item).forEach((key) => {
          let param = item.params.find((param) => param.nombre === key)
          if (param) {
            if (typeof event.item[key] === 'object' && param.tipo === 'emplazamiento') {
              param.valor = [event.item[key].nombre]
              param.emplazamiento_uuid = event.item[key].uuid
              param.emplazamientoUuid = event.item[key].uuid
            } else {
              param.valor = Array.isArray(event.item[key]) ? event.item[key] : [event.item[key]]
            }
            //if (param.valor[0].length === 36) {
            //  param.emplazamiento_uuid = param.valor[0]
            //  param.valor = [this.sites.find((site) => site.direccion.uuid === param.valor[0]).nombre]
            //}
          }
        })
        if (item.emplazamiento) delete item.emplazamiento
        const xhr = await this.$store.dispatch('auditorias/updateAmpliacion', {
          producto: item,
        })

        if (xhr) {
          this.$store.commit('notification/show', {
            text: 'Ampliación actualizada correctamente',
            color: 'success',
          })
          if (this.subalcance_tipo !== 'grid') {
            window.location.reload()
          } else {
            this.$emit('init')
          }
          //this.$emit('init')
        } else {
          this.$store.commit('notification/show', {
            text: 'Ha ocurrido un error actualizando la ampliación',
            color: 'error',
            timeout: 3000,
          })
        }
      }
    },

    async addAmpliaciones() {
      // addAmpliaciones which are in productosFromIteracion
      let ampliaciones = this.productosFromIteracion.map((producto) => {
        /*
        let ampliacion = {
          id: uuid.v4(),
          tipo: producto.tipo,
          direccion: '',
          configProducto: [],
        }

        producto.params.forEach((param) => {
          let item = {
            nombre: param.nombre,
            valor: param.valor,
            tipo: param.tipo,
            estatico: param.estatico,
            multiple: param.multiple,
            orden: param.orden,
            imprimible: true,
            subalcance: this.auditoria.subalcance.uuid,
          }

          ampliacion.configProducto.push(item)
        }) */

        return producto['@id']
      })

      const xhr = await this.$store.dispatch('auditorias/addAmpliacion', {
        auditoria: this.auditoria.uuid,
        ampliacion: ampliaciones,
        mode: 2,
      })

      if (xhr) {
        this.$store.commit('notification/show', {
          text: 'Ampliaciones añadidas correctamente',
          color: 'success',
        })

        this.$emit('init')
      } else {
        this.$store.commit('notification/show', {
          text: 'Ha ocurrido un error añadiendo las ampliaciones',
          color: 'error',
          timeout: 3000,
        })
      }
    },
    getHeaders(uuid) {
      // need to get one product to get the keys
      console.log('uuid', uuid)
      console.log('sites', this.sites)
      const product = this.sites.find((item) => item.uuid === uuid).productos[0]
      //this.productos.find((producto) => producto.emplazamiento.uuid === uuid)
      let headers = [
        { text: '', value: 'actions', sortable: false, width: 130 },
        /* {
          text: 'Instalación',
          value: 'instalacion',
          cellClass: 'subData',
          sortable: false,
          width: 130,
        }, */
      ]
      if (this.subalcance_tipo !== 'grid') {
        headers.push({
          text: 'Instalación',
          value: 'instalacion',
          cellClass: 'subData',
          sortable: false,
          width: 130,
        })
      }
      let new_headers = Object.values(product.params).map((item) => {
        return {
          text: item.nombre,
          value: item.nombre,
          sortable: false,
        }
      })
      headers = headers.concat(new_headers)
      return headers
    },

    getHeaders2(uuid) {
      // need to get one product to get the keys
      const product = this.sites.find((item) => item.uuid === uuid).productos[0]
      //this.productos.find((producto) => producto.emplazamiento.uuid === uuid)
      return Object.values(product.params).map((item) => {
        return {
          text: item.nombre,
          value: item.nombre,
          sortable: false,
        }
      })
    },
    crearOtraActividad() {
      this.ui.dialog_ = true
      this.ui.isNew = true
    },

    rowAction(params) {
      this.ui.dialog = true
      this.ui.isNew = false

      const original = this.sites.find((e) => e.uuid == params.uuid)
      this.itemSelected = { ...original }
    },

    //+-------------------------------------------------
    // rowAction_()
    // The same as rowAction for maquila and other type of sites
    // -----
    // Created on Mon Feb 21 2022
    //+-------------------------------------------------
    rowAction_(params) {
      let new_params = params.params
      if (this.subalcance_tipo !== 'grid') {
        //delete of new params the item of tipo emplazamiento
        new_params = new_params.filter((item) => item.tipo !== 'emplazamiento')
      }
      console.warn('🥸 Params', new_params)

      new_params = Object.values(new_params).map((item, index) => {
        return {
          nombre: item.nombre,
          valor: item.valor ? item.valor : item.valor[0],
          tipo: item.tipo,
          estatico: item.estatico,
          selector: item.linkEstatico,
          multiple: item.multiple,
          orden: item.orden ? item.orden : index,
          imprimible: true,
          subalcance: this.auditoria.subalcance.uuid,
        }
      })
      let group = 'productos'
      let uuid = params.uuid
      let gridConfig = new_params
      let item = {}
      new_params.forEach((pa) => {
        let nombre = pa.nombre
        let valor = pa.valor
        item[nombre] = [valor]
      })
      this.$refs.form_grid_dinamico.edit(group, uuid, gridConfig, item, params.tipo)

      //this.ui.dialog_ = true
      //const original = this.sites.find((e) => e.uuid == params.uuid)
      //this.itemSelected = { ...original }
    },

    closeAmpliacion() {
      this.ui.dialog = false
      this.ui.dialog_ = false
      this.ui.isNew = false
      this.itemSelected = null
    },

    mergeSiteDataWithSite() {
      let certificadoSites = []
      let sites = [...this.sites]

      sites.forEach((site, i) => {
        delete site.estado

        if (site.certificado !== true) {
          return
        }

        // comentado porque hay que habilitar el resto de empresas.
        // if (site.tipo !== 'actividad propia') {
        // return
        // }

        site.site_data.forEach((data) => {
          if (!data.value) {
            return
          }

          if (data.value.indexOf(',') > 0) {
            data.value = data.value.split(',')
          }

          if (typeof site[data.keyname] == 'undefined') {
            site[data.keyname] = data.value
          }

          if (typeof site[data.keyname] == 'string') {
            site[data.keyname] = [site[data.keyname]]
          }

          // if (typeof site[data.keyname] == 'object') {
          //   if (site[data.keyname].indexOf(data.value) == -1) {
          //     site[data.keyname].push(data.value)
          //   }
          // }
        })

        certificadoSites.push(site)
      })

      this.certificadoSites = certificadoSites
    },

    //+-------------------------------------------------
    // getSiteHeaders()
    // previously known as setcolumndefs
    // Gets the first site and extracts attributes
    // and adds them as columns
    // -----
    // Created on Fri Sep 10 2021
    //+-------------------------------------------------
    getSiteHeaders() {
      if (this.certificadoSites.length == 0) return

      let sample = this.certificadoSites.find((e) => this.db.propias.indexOf(e.tipo) > -1)
      // let sample = this.certificadoSites[0]

      let avoid = [
        'data',
        'site_data',
        'combinadas',
        'instalacion',
        'instalacion_nombre',
        'instalacion_codigo',
        'metadata',
        'certificado',
        'planificable',
        'manejo',
        'subtipo',
        'source_parent_uuid',
        'source_iteracion_uuid',
        'expediente_uuid',
        'uuid',
        'parent_uuid',
        'iteracion_uuid',
        'tipo',
      ]

      this.table.dinamicHeaders = [...this.table.headers]
      // console.warn(sample)
      for (const key in sample) {
        const value = sample[key]
        if (avoid.indexOf(key) > -1) continue

        // console.warn(key, value)
        let name = key.replace(/_/g, ' ')
        let length = name.length * 5

        // let index = this.table.headers.length - 1
        // this.table.headers.splice(index, 0, {

        this.table.dinamicHeaders.push({
          text: name,
          value: 'data.' + key,
          cellClass: 'subData',
          sortable: false,
          width: length,
        })
      }

      if (this.disabled) {
        this.table.dinamicHeaders = this.table.dinamicHeaders.filter((e) => e.value !== 'actions')
      }
    },

    checkIfHasChildSites() {
      for (const site of this.sites) {
        if (site.hasOwnProperty('parent_uuid')) {
          return true
        }
      }
      return false
    },

    sitesObject(siteData) {
      let data = []
      for (const item in siteData) {
        if (item == 'instalacionObject') continue
        data.push({
          key: item,
          value: siteData[item],
        })
      }

      return data
    },

    doImportGrid() {
      this.$refs.importGrid.doImport()
    },

    async importSites(data) {
      if (data && data.ecologicos.length) {
        data.ecologicos.forEach(async (site) => {
          await this.save(site, 'create')
        })
      }
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    submit(item) {
      if (this.itemSelected?.uuid) this.update(item)
      else this.create(item)
    },

    create(item) {
      this.save(item, 'create')
    },

    update(item) {
      this.save(item, 'update')
    },

    async save(item, method = 'create') {
      this.loading = true
      let payload = {}

      if (this.itemSelected) payload = { ...this.itemSelected }
      else payload = { ...item }

      let siteData = this.sitesObject(item)

      payload.data = siteData
      payload.originalData = item

      let inst = this.cliente.instalaciones.find((e) => e.rgseaa == item.instalacion_codigo)
      payload.instalacion = inst

      if (!this.itemSelected?.uuid) {
        let site = ''
        let firstSite = this.items[0]

        if (this.alcance.codigo == 'produccion_ecologica') {
          site = this.sites.find(
            (e) => e.instalacion.rgseaa == this.auditoria.instalaciones_data[0].rgseaa
          )
        } else {
          site = this.sites.find((e) => e.instalacion.rgseaa == item.instalacion_codigo)
        }
        payload.uuid = uuid.v4()
        payload.nombre = site?.nombre || item.instalacion_nombre

        // Set in back
        // payload.planificable = site?.planificable || true

        if (this.cliente?.empresa?.codigo == 'certicalidad') {
          if (item.otras_actividades && item.tipo_act) payload.tipo = item.tipo_act
          else if (firstSite?.data?.tipo) payload.tipo = firstSite.data.tipo
          else payload.tipo = 'actividad propia'

          // SET in BACK
          // if (payload.tipo.indexOf('maquila') > -1) payload.planificable = false
        }

        // payload.tipo = item.otras_actividades ? item.tipo_act : firstSite.data.tipo // 'actividad propia' //site?.tipo
        if (!payload.instalacion) payload.instalacion = site?.instalacion || item.instalacionObject

        payload.iteracion_uuid = site?.iteracion_uuid || this.auditoria.iteracion.uuid
        if (item.iteracion_uuid) payload.iteracion_uuid = item.iteracion_uuid
      }

      try {
        if (method === 'create') {
          await this.$store.dispatch('sites/' + method, payload)
          await this.$store.dispatch('auditorias_ampliacion/create', {
            site: payload.uuid,
            auditoria: this.$route.params.uuid,
            instalacion: payload.instalacion,
          })
        } else {
          await this.$store.dispatch('auditorias_ampliacion/update', {
            site: payload.uuid,
            auditoria: this.$route.params.uuid,
            originalData: payload.originalData,
            data: payload.data,
          })
        }

        this.$store.commit('notification/show', {
          text: 'Cambios guardados correctamente',
          color: 'success',
        })

        if (this.$nuxt.isOffline) {
          this.offline.push(payload)
          this.dialog = false
          return
        }

        this.dialog = false
        this.$emit('init')
      } catch (e) {
        console.info(e)
        this.$store.commit('notification/show', {
          text: 'Ha ocurrido un error creando la ampliación',
          color: 'error',
          timeout: 3000,
        })
      } finally {
        this.loading = false
      }
    },

    async remove(permanent = false) {
      this.ui.loading = true
      this.ui.confirm = false
      const xhr = await this.$store.dispatch('auditorias_ampliacion/delete', {
        auditoria: this.auditoria.uuid,
        site: this.ui.confirmRemove['@id'],
        delete: permanent,
      })
      this.ui.confirmRemove = false

      this.reload()
    },

    async removeCompletely(permanent = false) {
      this.ui.loading = true
      this.ui.confirm = false
      const xhr = await this.$store.dispatch('auditorias_ampliacion/deleteAlsoFromIteracion', {
        auditoria: this.auditoria.uuid,
        site: this.ui.confirmRemove['@id'],
        delete: permanent,
      })

      this.ui.confirmRemove = false

      this.reload()
    },

    async preloadOffline() {
      if (!$nuxt.$store.getters['offline/caching']) {
        return
      }

      await this.$store.dispatch('tramites/list')
    },

    async init() {
      this.certificadoSites = []
      this.mergeSiteDataWithSite()
      this.getSiteHeaders()
      this.preloadOffline()
      this.ui.loading = false

      this.productos = await this.$store.dispatch(
        'subalcances/getProductos',
        this.auditoria.subalcance.uuid
      )
    },

    reload() {
      this.$emit('init')
      // this.$nuxt.$emit('auditoria:reload')
    },

    updateSelectedProductos(productos) {
      const productosEmplazamientos = this.emplazamientos.flatMap(
        (emplazamiento) => emplazamiento.productos
      )

      this.productosFromIteracion = productos.filter((producto) => {
        return !productosEmplazamientos.some(
          (productoEmplazamiento) => productoEmplazamiento.uuid === producto.uuid
        )
      })
    },
  },

  created() {
    this.init()
  },
}
</script>

<style>
.v-data-table.disabled {
  opacity: 0.5;
  pointer-events: none;
}

th span {
  white-space: nowrap;
  text-transform: capitalize;
}

.subData {
  color: rgb(146, 146, 146);
  font-size: 12px !important;
  white-space: nowrap;
}

.capitalize {
  text-transform: capitalize;
}

/* Estilo personalizado para la barra de desplazamiento horizontal */
.v-data-table > .v-data-table__wrapper > .v-data-table__overflow {
  overflow-x: auto;
}
</style>
