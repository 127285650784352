<template>
  <div>
    <div v-if="item.tipo == 'html'" :key="'wysiwyg-' + ui.key">
      <RichTextEditor :initial-value="item.content[0]" @input="updateContent"></RichTextEditor>
    </div>

    <div v-if="item.tipo == 'table'" :key="'table-' + ui.key">
      <table-block-editor ref="tableEditor" :_table="item.content" />
    </div>

    <div v-if="item.tipo == 'simpletable'" :key="'table-' + ui.key">
      <simpletable-block-editor ref="simpletableEditor" :_table="item.content" />
    </div>

    <!-- Confirmación de eliminar -->
    <Confirmation v-model="ui.confirm" :text="$t('bloquesWidgetsBlockembed.confirmRemove')"
      :textButton="$t('bloquesWidgetsBlockembed.delete')" @confirm="reset" />
  </div>
</template>


<script>
import { uuid } from 'vue-uuid'
import _arr from '~/util/arrayHelper'
import format from '~/util/formatHelper'

import Confirmation from '~/components/Confirmation'
import RichTextEditor from '~/components/RichTextEditor'
import tableBlockEditor from '../edit/tableBlockEditor'
import simpletableBlockEditor from '../edit/simpletableBlockEditor'

export default {
  name: 'bloques-embed',
  props: ['block', 'submodulo_ref', 'disabled'],

  components: {
    Confirmation,
    RichTextEditor,
    tableBlockEditor,
    simpletableBlockEditor,
  },

  data() {
    return {
      item: {
        alcance: null,
        tipo: 'html',
        nombre: '',
        tipo: '',
        content: [''], // Ensure it's always an array with at least one element
        orden: 1,
        isNew: false,
        plantilla: null,
      },

      ui: {
        key: 0,
        loading: false,
        confirm: false,
        confirmRemove: false,
      },
    }
  },

  methods: {
    async doUpdate(block) {
      let method = 'update'
      await this.store(method)
      this.ui.key++
      $nuxt.$emit('bloques:refresh', {})

    },

    async doUpdateWithEmptyContent(block) {
      let method = 'update'
      await this.store(method, true)
      this.ui.key++
      $nuxt.$emit('bloques:refresh', {})

    },

    async doClone() {
      await this.store('create')
      $nuxt.$emit('bloques:refresh', {})
    },

    async doReset() {
      this.ui.confirm = true
      this.ui.confirmRemove = this.item
    },

    toFormatos(data) {
      let formatos = []

      for (let i in data.rows) {
        let row = {}
        let empty = true

        for (const o in data.rows[i]) {
          let el = data.rows[i][o]
          let head = data.headers[o]?.label
          if (el) empty = false
          row[format.stringToslug(head)] = el
        }

        if (!empty) {
          formatos.push(row)
        }
      }

      return formatos
    },

    async store(method = 'update', emptyContent = false) {
      this.ui.loading = true;

      const payload = { ...this.item };
      console.log('parsing:', payload);
      if (method == 'create') {
        //payload.plantilla = payload.plantilla?.uuid ? payload.plantilla?.uuid : payload.uuid
        //payload.uuid = uuid.v4()
        //payload.modulo_ref = this.$route.params.uuid
      }
      if (!emptyContent) {
        if (payload.tipo == 'table') {
          payload.content = { ...this.$refs.tableEditor.table };
          payload.content.formatos = this.toFormatos(payload.content);
        }
        if (payload.tipo == 'html') payload.content = [payload.content];

        console.log('saving:', payload.content);
      } else payload.content = payload.plantilla.content;
      try {
        console.log('payload:', payload);
        if (payload.alcance) payload.alcance = payload.alcance['@id'];
        if (payload.auditoria) payload.auditoria = payload.auditoria['@id'];
        if (payload.solicitud) payload.solicitud = payload.solicitud['@id'];
        if (payload.checklist) payload.checklist = payload.checklist['@id'];
        if (payload.subalcance) payload.subalcance = payload.subalcance['@id'];
        if (payload.plantilla) payload.plantilla = payload.plantilla['@id'];
        let xhr = await this.$store.dispatch('bloques/' + method, payload);

        this.item.uuid = payload.uuid;
        this.item.isNew = false;
        this.item.plantilla = xhr.plantilla;
      } catch (e) {
        console.info(e);
        this.$store.commit('notification/show', {
          text: this.$t('bloquesWidgetsBlockembed.saveError'),
          color: 'error',
          timeout: 3000,
        });
      } finally {
        this.ui.loading = false;
      }
    },

    async reset() {
      this.ui.loading = true;
      this.ui.confirm = false;

      const xhr = await this.$store
        .dispatch('bloques/delete', this.ui.confirmRemove)
        .then(() => {
          this.$store.commit('notification/show', {
            text: this.$t('bloquesWidgetsBlockembed.removeSuccess'),
            color: 'success',
            timeout: 3000,
          });
          this.$emit('bloquesWidgetsBlockembed:refresh', {});
        })
        .catch(() => {
          this.$store.commit('notification/show', {
            text: this.$t('bloquesWidgetsBlockembed.removeError'),
            color: 'error',
            timeout: 3000,
          });
        })
        .finally(() => {
          this.ui.loading = false;
          $nuxt.$emit('bloquesWidgetsBlockembed:refresh', {});
        });
    },


    async getTemplate() {
      const xhr = await this.$store.dispatch('plantillas-bloques/get', this.item.plantilla.uuid)
      this.item = { ...xhr }
      this.item.isNew = true
      this.item.modulo_ref = this.ui.confirmRemove.modulo_ref
      this.ui.key = Date.now()
    },

    async init() {
      this.item = { ...this.block }
      if (!this.item.tipo && this.item.plantilla?.tipo) this.item.tipo = this.item.plantilla.tipo
      if (this.submodulo_ref) this.item.submodulo_ref = this.submodulo_ref
      if (this.item.tipo == 'html')
        this.item.content = this.item.content.length ? this.item.content : ['']
    },

    updateContent(newContent) {
      this.item.content[0] = newContent
    }
  },

  mounted() {
    this.init()
  },
}
</script>
