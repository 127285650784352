import ROLE from './role'

export default [
  {
    name: {
      es: 'Mis datos',
      en: 'My Data',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'portal/perfil',
    icon: 'mdi-account-edit',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es: 'Técnicos',
      en: 'Technicians',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'portal/tecnicos',
    icon: 'mdi-account-hard-hat',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es: 'Instalaciones',
      en: 'Facilities',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'portal/instalaciones',
    icon: 'mdi-home-city-outline',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es: 'Productores',
      en: 'Producers',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'portal/cooperativas',
    icon: 'mdi-package-variant-closed',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es: 'Etiquetas',
      en: 'Labels',
      code: 'menu.etiquetas',
    },
    block: 'gestion',
    modulo: 'etiquetas',
    type: 'link',
    link: 'portal/etiquetas',
    icon: 'mdi-texture-box',
    role: ROLE.ROLE_AUDITOR,
    onlyFor: ['quesomanchego'],
  },

  {
    name: {
      es: 'Marcas',
      en: 'Brands',
    },
    block: 'gestion',
    modulo: 'marcas',
    type: 'link',
    link: 'portal/marcas',
    icon: 'mdi-cards',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
    onlyFor: ['quesomanchego'],
  },

  {
    name: {
      es: 'Documentos',
      en: 'Documents',
      en_US: 'Documents',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'portal/documentos',
    icon: 'mdi-cloud-outline',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es: 'Autoevaluaciones',
      en: 'Self-evaluations',
      en_US: 'Requests',
    },
    block: 'administracion',
    modulo: 'solicitudes',
    type: 'menu',
    link: null,
    icon: 'mdi-book-check-outline',
    role: ROLE.ROLE_CLIENT,
    onlyFor: ['quesomanchego'],
    items: [
      {
        name: {
          es: 'Crear nueva',
          en: 'Create new',
        },
        submodulo: 'nueva-solicitud',
        link: 'portal/autoevaluaciones/create',
        icon: 'mdi-plus-thick',
        role: ROLE.ROLE_CLIENT,
      },
      {
        name: {
          es: 'Ver evaluaciones',
          en: 'View evaluations',
        },
        submodulo: 'solicitudes',
        link: 'portal/autoevaluaciones',
        icon: 'mdi-text-box-outline',
        role: ROLE.ROLE_CLIENT,
      },
    ],
  },

  {
    name: {
      es: 'Solicitudes',
      en: 'Requests',
      en_US: 'Requests',
    },
    block: 'administracion',
    modulo: 'solicitudes',
    type: 'menu',
    link: null,
    icon: 'mdi-text-box-check-outline',
    role: ROLE.ROLE_CLIENT,
    items: [
      {
        name: {
          es: 'Crear nueva',
          en: 'Create new',
        },
        submodulo: 'nueva-solicitud',
        link: 'portal/solicitudes/create',
        icon: 'mdi-plus-thick',
        role: ROLE.ROLE_CLIENT,
      },
      {
        name: {
          es: 'Ver solicitudes',
          en: 'View requests',
        },
        submodulo: 'solicitudes',
        link: 'portal/solicitudes',
        icon: 'mdi-text-box-outline',
        role: ROLE.ROLE_CLIENT,
      },
    ],
  },

  {
    name: {
      es: 'Auditorías',
      en: 'Audits',
    },
    block: 'administracion',
    modulo: 'auditorias',
    type: 'link',
    link: 'portal/auditorias',
    icon: 'mdi-ballot-outline',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
  },

  {
    name: {
      es: 'Certificados',
      en: 'Certificates',
    },
    block: 'administracion',
    modulo: 'certificados',
    type: 'link',
    link: 'portal/certificados',
    icon: 'mdi-file-certificate-outline',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
    notFor: ['coplaca'],
  },

  {
    name: {
      es: 'Informes',
      en: 'Reports',
    },
    block: 'administracion',
    modulo: 'informes',
    type: 'link',
    link: 'portal/informes',
    icon: 'mdi-card-bulleted-outline',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
    onlyFor: ['coplaca'],
  },

  {
    name: {
      es: 'Noticias',
      en: 'News',
      en_US: 'News',
    },
    block: 'gestion',
    modulo: 'noticias',
    type: 'link',
    link: 'newsboard',
    icon: 'mdi-newspaper',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es: 'Control de procesos',
      en: 'Process Control',
      en_US: 'Process control',
    },
    block: 'administracion',
    modulo: 'reportes',
    type: 'link',
    link: 'portal/control-procesos',
    icon: 'mdi-timer-star',
    role: ROLE.ROLE_AUDITOR,
  },
]
