<template>
  <v-autocomplete
    v-if="subalcancetipo"
    ref="emplazamientos"
    v-model="selected"
    :items="planificableItems"
    label="Emplazamientos"
    item-text="nombre"
    item-value="uuid"
    dense
    return-object
    :disabled="disabled"
    @change="rowSelected"
    :required="required"
    :rules="rules"
    :multiple="multiple">
    <template slot="prepend-item" v-if="multiple">
      <v-list-item>
        <v-list-item-action>
          <v-checkbox
            v-model="selected2"
            :disabled="disabled"
            color="primary"
            @click="toggle"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-autocomplete>

  <v-autocomplete
    v-else
    ref="emplazamientos"
    v-model="selected"
    :items="planificableItems"
    :label="label ? 'Emplazamientos' : ''"
    item-text="nombre"
    dense
    return-object
    :disabled="disabled"
    @change="rowSelected"
    :required="required"
    :rules="rules"
    :multiple="multiple">
    <template slot="prepend-item" v-if="multiple">
      <v-list-item>
        <v-list-item-action>
          <v-checkbox
            v-model="selected2"
            :disabled="disabled"
            color="primary"
            @click="toggle"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-autocomplete>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\forms\SelectEmplazamientos.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Tue May 02 2023
 **/

import * as UTIL from '~/util'

export default {
  name: 'SelectEmplazamientos',
  props: {
    value: [String, Array, Object],
    disabled: { type: Boolean, default: false },
    tipo: { type: String, default: '' },
    user: { type: Object, default: () => ({}) },
    required: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    subalacance_tipo: { type: String, default: '' },
    label: { type: Boolean, default: true },
  },
  data() {
    return {
      UTIL: UTIL,
      selected: [],
      items: [],
      indeterminate: false,
      selected2: [],
    }
  },
  watch: {
    value(val) {
      if (val !== null || val !== '') {
        if (this.multiple) {
          this.selected = val
        } else {
          this.selected = Array.isArray(val) ? val[0] : val
          //validate if this.selected is uuid
          if (this.esUUID(this.selected) && this.items.length > 0) {
            let emplazamiento = this.items.filter((e) => e.uuid === this.selected)
            this.selected = emplazamiento[0].uuid
          }
        }

        this.$forceUpdate()
      }
    },
  },

  computed: {
    rules() {
      if (this.required) {
        return this.multiple
          ? [(v) => (!!v && !!v.length) || 'Obligatorio']
          : [(v) => !!v || 'Obligatorio']
      } else return []
    },
    planificableItems() {
      const sites = this.items

      return sites
    },

    subalcancetipo() {
      return this.subalacance_tipo !== 'grid' ? true : false
    },
  },
  methods: {
    esUUID(string) {
      const UUID_REGEX =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
      return UUID_REGEX.test(string)
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selected.length > 0) {
          this.selected = []
          this.$emit('input', this.selected)
        } else {
          this.selected = this.items.slice()
          this.$emit('input', this.selected)
        }
      })
    },
    rowSelected(item) {
      this.$emit('input', item)
    },
    async getDirecciones(tipo) {
      let payload = {
        uuid: this.user.uuid,
        tipo: tipo.valor,
      }
      let jxr = null
      jxr = await this.$store.dispatch('direcciones/user', payload)
      this.items = jxr
    },
    async init() {
      let tipo = null
      const xhr = await this.$store.dispatch('estaticos/getTipo', this.tipo)
      if (xhr) {
        tipo = xhr[0].valor.filter((e) => e.emplazamiento === true)[0]
      }
      //debugger
      await this.getDirecciones(tipo)
      //if (this.multiple) {
      //  //add option select all to the first position
      //  this.items.unshift({ nombre: 'Select All', value: 'all' })
      //}
    },
    setValue() {
      if (this.value) {
        if (this.multiple) {
          this.selected = this.value
        } else {
          this.selected = Array.isArray(this.value) ? this.value[0] : this.value
        }
      }
    },
  },
  mounted() {
    this.init()
    this.setValue()
  },
}
</script>
