/**
 * @project: certiapp-nuxt
 * @file:    store/documentos.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 8th July 2021
 * Last Modified: Fri Jul 28 2023
 **/

import { uuid } from 'vue-uuid'

function getParameterCaseInsensitive(object, key) {
  return object[Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase())]
}

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Returns all families
  // -----
  // Created on Tue Jun 01 2021
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.get(`documentos`)

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async getForEmpresa({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.get(`documentos?empresa.uuid=${params.uuid}`)

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async listPaginated({ rootState }, params = {}) {
    rootState.loading = true
    const queryParams = []

    // Construir los parámetros de consulta
    Object.keys(params).forEach((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        value.forEach((val) => {
          queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
        })
      } else {
        queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }
    })

    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
    try {
      const res = await this.$axios.get(`documentos${queryString}`)
      rootState.loading = false

      if (res?.data && res.data['hydra:member']) {
        return {
          items: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
        }
      } else {
        return {
          items: [],
          totalItems: 0,
        }
      }
    } catch (error) {
      rootState.loading = false
      console.error('Error al obtener documentos:', error)
      return {
        items: [],
        totalItems: 0,
      }
    }
  },
  //+-------------------------------------------------
  // create()
  // v2 of the create method in gestor_documental
  // -----
  // Created on Thu Jul 15 2021
  // Updated on Tue Sep 21 2021
  //+-------------------------------------------------

  async create({ rootState }, params) {
    rootState.loading = true

    let data = new FormData()
    data.append('uuid', params.uuid || uuid.v4())
    data.append('archivoDocumento', params.file)
    data.append('titulo', params.titulo)
    data.append('modulo', params.modulo)
    data.append('moduloRef', params.moduloRef === 'undefined' ? '' : params.moduloRef)
    data.append('submodulo', params.submodulo === 'undefined' ? '' : params.submodulo)
    data.append('submoduloRef', params.submoduloRef === 'undefined' ? '' : params.submoduloRef)

    // preferencia sobre cliente si se envian los dos
    //if(params.contacto) data.append('contacto', params.contacto)
    data.append('cliente', params.cliente)

    data.append('isPublic', params.visible ? true : false)
    data.append('autogenerado', params.generated ? 1 : 0)
    if (params.contacto) data.append('contacto', '/api/contactos/' + params.contacto)
    if (params.cliente) data.append('user', '/api/usuarios/' + params.cliente)
    if (params.empresa) data.append('empresa', '/api/empresas/' + params.empresa)
    if (params.notifyEmail) data.append('notifyEmail', params.notifyEmail)
    if (params.notifyPush) data.append('notifyPush', params.notifyPush)

    const xhr = await this.$axios.post(`documentos`, data)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Fri Jun 04 2021
  //+-------------------------------------------------
  // async update({rootState}, params) {
  //   rootState.loading = true
  //   await this.$axios.patch(`metadata/${params.uuid}`, {
  //     metadata: params.uuid,
  //     nombre: params.nombre,
  //     codigo: params.codigo,
  //     descripcion: params.descripcion || '',
  //     datos: params.datos,
  //     metadata_tipo: params.tipo,
  //   })
  //   rootState.loading = false
  // },

  //+-------------------------------------------------
  // toggleVisibility()
  // Created on Thu Apr 29 2021
  //+-------------------------------------------------
  async toggleVisibility({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.patch(`documentos/${params.uuid}/toggle-visible`, {
      documento: params.uuid,
      visible: params.visible,
    })

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Fri Oct 22 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`documentos/${item.uuid}`)
    rootState.loading = false
  },

  async download({ rootState }, doc) {
    rootState.loading = true
    console.log('Descargando documento:', doc)
    let href = `/public/documentos/${doc.uuid}`
    if (doc.contentUrl) href = doc.contentUrl
    this.$axios.get(href, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.data.type })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const contentDisposition = getParameterCaseInsensitive(
          response.headers,
          'Content-Disposition'
        )
        let fileName = href.substring(href.lastIndexOf('/') + 1)
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1]
        }
        if (doc.nombreArchivo) fileName = doc.nombreArchivo
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch((e) => {
        throw e
      })
      .finally(() => {})
  },
}
